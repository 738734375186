import * as Styled from "./internalNavigationStyles";
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useLocation } from "@reach/router";

const InternalNavigation = () => {
const location = useLocation();
const [loaded, setLoaded] = React.useState(false);
React.useEffect(() => {
setLoaded(true);
}, []);


const navigationDataQuery = useStaticQuery(graphql`
query internalNavigationQuery {
    contentfulSolEcolution(name: { eq: "In The Media" }) {
    sections {
        ... on ContentfulContentList {
        id
        type
        title
        subtext
        list {
            id
        }
        }
    }
    }
}
`);

const navigations =
navigationDataQuery?.contentfulSolEcolution?.sections?.filter(
    (x) => x.type === "contentListWith3Items"
);
return loaded ? (
<Styled.MainDiv>
    <Styled.NavContainer>
    <Styled.NavHeader>On This Page :</Styled.NavHeader>
    {navigations?.map((nav, index) => {
        return (
        nav?.list?.length > 0 && (
            <Styled.NavItem
            key={index}
            href={`#${nav?.subtext}`}
            className={
                location?.hash?.includes(nav?.subtext)
                ? "active"
                : !location?.hash
                ? index === 0
                    ? "active"
                    : ""
                : ""
            }
            >
            {nav?.title}
            </Styled.NavItem>
        )
        );
    })}
    </Styled.NavContainer>
</Styled.MainDiv>
) : (
<></>
);
};

export default InternalNavigation;
